import { v4 as uuidv4 } from 'uuid';

// HEAD DATA
export const headData = {
  title: 'Nick Jeske | Software Engineer, Android Developer, & Blogger', // e.g: 'Name | Developer'
  author: 'Nick Jeske',
  keywords: 'Software Engineer, Android Developer, JavaScript, ReactJS, NodeJS, React, Node, AWS, Java, Spring Boot, Android, HTML, CSS, Postgres, MySQL, SQLite, Software Engineer Blog, Blog, Portfolio, Portfolio Website',
  lang: 'en', // e.g: en, es, fr, jp
  description: "Discover a software engineer's portfolio & blog. Unleash innovation with transformative projects, insightful articles, and cutting-edge software solutions.", // e.g: Welcome to my website
  image: 'favicon1.png',
  siteUrl: 'https://www.nickjeske.com',
  twitterUsername: `@NJeske49`,
};

// HEADER DATA
export const headerData = {
  alt: 'Nick Jeske Header Logo',
};

// HERO DATA
export const heroData = {
  title: 'Hello my name is',
  name: 'Nick Jeske',
  subtitle: `I am a Software Engineer, Android Developer, & Blogger`,
  cta: '',
};

// // ABOUT DATA
// export const aboutData = {
//   img: 'profile.jpg',
//   paragraphOne: `I'm a professional and passionate Software Engineer focused on efficiency and constant learning.`,
//   paragraphTwo:
//     'I have experience working in large financial and manufacturing enterprises with modernizing legacy applications. Looking to further my skills and become a Full Stack Developer using Java and Javascript frameworks like React.js and Node.js.',
//   paragraphThree:
//     'I would love to set up an introduction call to discuss how I could help your business and make your goals happen.',
//   resume: 'https://gitconnected.com/njjeske/resume', // if no resume, the button will not show up
// };

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: `I am a software engineer with a passion for creating innovative and intuitive software applications. I have a Bachelor's degree in Computer Science and have primarily worked in the financial and manufacturing industries, gaining valuable experience in developing software solutions in an agile environment.`,
  paragraphTwo:
    'One of my notable accomplishments is creating an Android app called The Next Trail that is available on the Google Play Store. This project allowed me to showcase my expertise in mobile app development and allowed me to gain valuable experience in working with Android development tools and languages such as Java, XML, and SQLite.',
  paragraphThree: `I am a highly motivated individual with excellent problem-solving abilities. I would love to set up an introduction call to discuss how I could help your business and make your goals happen.`,
  resume: 'https://gitconnected.com/njjeske/resume', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv4(),
    img: 'TheNextTrail.JPG',
    title: 'The Next Trail (Freelance Work)',
    info:
      'The Next Trail was developed for Snowmobilers and Off-Road enthusiasts by an avid snowmobiler. The Android app allows you to see where you are on Outdoor Trails. View trails and routes, ride areas, points of interest and local events.',
    info2:
      'Tasked to create Android app by using technologies like Java, XML, SQLite, Google Maps API, Google Sign on and more! The app is on the Google Play store.',
    repo: 'https://play.google.com/store/apps/details?id=com.blabapps.thenexttrail',
    url: '', // if no repo, the button will not show up
  },
  {
    id: uuidv4(),
    img: 'webstore.jpg',
    title: 'Ecommerce-Website',
    info:
      'A standard Ecommerce Website that allows users to sign in / sign up, add shop items, checkout items, etc.',
    info2:
      'A project to improved my skillset by using technologies like React, Redux, Google Firebase, and more!',
    repo: '',
    url: 'https://github.com/NJJeske/Ecommerce-Website', // if no repo, the button will not show up
  },

  {
    id: uuidv4(),
    img: 'FRAProject.jpg',
    title: 'Face Recognition App',
    info: `A React.js website that is used to highlight faces of random images. This website uses a Machine Learning API Key called Clarifai to provide a location of a person's face when given an image.`,
    info2:
      'The front-end consumes a Rest Service built of Express (Node.js back-end server) and Postgres SQL (Relational Database).' /* The website is deployed through Heroku.' */,
    repo: '',
    url: 'https://github.com/NJJeske/Face-Recognition-App-Frontend', // if no repo, the button will not show up
  },
  // {
  //   id: uuidv4(),
  //   img: 'project.jpg',
  //   title: 'Move List Website',
  //   info:
  //     'A simple project that displays and searches top rated movies with a scrollbar. The searchbar will filter the names of the movies depending on their title.',
  //   info2: `React web development project. This website uses an API called The Movie Database API to fetch a JSON object with the following info (movie images, title, and popularity).The website is deployed through Netlify.`,
  //   repo: 'https://movie-list-website.netlify.app/',
  //   url: 'https://github.com/NJJeske/movielist-website', // if no repo, the button will not show up
  // },
  {
    id: uuidv4(),
    img: 'UWM.jpg',
    title: 'Senior Design Project',
    info:
      'Created and collaborated with classmates and professionals from Northwestern Mutual to design and implement a portfolio builder application.',
    info2:
      'I learned new technologies like Java (Spring-Boot), Javascript (React.js), MySQL, and AWS.',
    repo: '',
    url: 'https://github.com/NJJeske/UWM-Capstone-Project-Frontend', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: `How can I help achieve your goals? Let's talk!`,
  btn: 'Connect',
  email: 'nickjeskedeveloper@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: uuidv4(),
    //   name: 'instagram',
    //   url: 'https://www.instagram.com/njjeske/',
    // },
    {
      id: uuidv4(),
      name: 'linkedin-in',
      url: 'https://www.linkedin.com/in/nicholasjjeske/',
    },
    {
      id: uuidv4(),
      name: 'github',
      url: 'https://github.com/NJJeske',
    },
  ],
};

// SKILLS DATA
export const skillsData = {
  skillsIcons: [
    {
      id: uuidv4(),
      icon: 'js',
      name: 'Javascript',
      color: 'yellow'
    },
    {
      id: uuidv4(),
      icon: 'java',
      name: 'Java',
      color: 'gray'
    },
    {
      id: uuidv4(),
      icon: 'git-alt',
      name: 'Git',
      color: 'red'
    },
    {
      id: uuidv4(),
      icon: 'react',
      name: 'React',
      color: 'blue'
    },
    {
      id: uuidv4(),
      icon: 'node-js',
      name: 'Node JS',
      color: 'green'
    },
    {
      id: uuidv4(),
      icon: 'microsoft',
      name: 'ASP .Net',
      color: 'blue'
    },
    {
      id: uuidv4(),
      icon: 'database',
      name: 'PostgresSQL',
      color: 'blue'
    },
    {
      id: uuidv4(),
      icon: 'android',
      name: 'Android',
      color: 'green'
    },
    {
      id: uuidv4(),
      icon: 'database',
      name: 'SQLite',
      color: 'lightblue'
    },
    {
      id: uuidv4(),
      icon: 'aws',
      name: 'AWS',
      color: 'orange'
    },
    {
      id: uuidv4(),
      icon: 'docker',
      name: 'Docker',
      color: 'blue'
    },
  ],
};

// EXPERIENCE DATA
export const experienceData = {
  experience: [
    {
      id: uuidv4(),
      year: '2017',
      jobs: [
        {
          name: 'Software Engineer Intern',
          company: 'Northwestern Mutal',
          desc:
            'Built SOAP & RESTful web services that served data to our JavaScript front end teams that handled over 4,000 daily users. Delivered 7 new portfolio and variable Universal Life products which surpassed sales by 11.5% of target by utilizing mainframe technologies on billing and collections team. Wrote reusable unit test cases to ensure quality control and identify bugs.',
        },
      ],
    },
    {
      id: uuidv4(),
      year: '2019',
      jobs: [
        {
          name: 'Assoc. Software Engineer',
          company: 'Northwestern Mutal',
          desc:
            'Reduced technical debt and defects by 50% by integrating SonarQube to CI/CD pipelines for Risk Servicing Ops team’s repositories. Converted Insured Service Accounts (ISA) bank lock box Unix scripts from FTP to SFTP which transfers 2.8 million dollars of daily payments to insurance batch jobs. Redesigned ISA Data Aggregator utilizing Spring Framework and Docker container to reduce security vulnerabilities and technical debt. Mentored 3 associate software engineers on the team on VB. Net, GitLab CI/CD, Mainframe, and documentation best practices.',
        },
      ],
    },
    {
      id: uuidv4(),
      year: '2021',
      jobs: [
        {
          name: 'Software Engineer',
          company: 'Liberty Mutual',
          desc:
            'Designed and implemented a streamlined data flow pattern in collaboration with Financial Data Resources (FDR) for processing 28 policy and billing systems, along with 16 claim systems, across 7 ecosystems. Utilized Jest test framework to surpass 80% code coverage target for premium and loss AWS CDK stacks. Created an Error Hospital UI to enable source system users to resubmit invalid data through premium and loss flows. Integrated OAuth 2.0 to accounting date service to ensure API calls are secured for General Ledger Processors.',
        },
        {
          name: 'Android Developer',
          company: 'Freelance Work',
          desc:
            'Produced an ATV / Snowmobile trails app called The Next Trail on the Google Play store that has more than 1,000 downloads. Developed vital features like subscriptions, event/business sign-up, and cloud-based trail recordings to enhance value for users. Tested multiple mobile devices to ensure the app is compatible with the latest version of the Android OS',
        },
      ],
    },
    {
      id: uuidv4(),
      year: '2024',
      jobs: [
        {
          name: 'Sr. Software Engineer',
          company: 'Direct Supply',
          desc:'',
        },
      ],
    },
  ],
};
